<script setup lang="ts">
</script>

<template>
  <div class="w-dvw lg:w-[520px] flex flex-col gap-y-32 px-10 my-32">
    <div class="flex flex-col items-center gap-y-8">
      <p class="text-xl font-semibold break-keep text-sky-600 dark:text-sky-400">
        Who We Are?
      </p>
      <p class="text-3xl lg:text-5xl font-bold break-keep text-center leading-tight">
        Unveil Values Via Interaction, GOODNODES.
      </p>
      <p class="text-xl text-center break-keep">
        안녕하세요, 우리는 상호작용을 통해 다양한 잠재 가치들을 현실로 만드는 굿노드 주식회사입니다.
      </p>
      <p class="text-xl text-center break-keep">
        이용자들의 잠재 니즈에 부합하는 제품을 만들고, 숨은 가치를 끌어내 비즈니스 모델을 폭발적으로 성장시키는 것이 팀 굿노드의 존재 이유입니다.
      </p>
    </div>
    <div class="flex flex-col items-center gap-y-8">
      <NuxtImg
        class="cursor-pointer"
        :src="'/image/goodnodes-logo.webp'"
        width="400px"
        preload
      />
      <p class="text-xl text-center break-keep">
        3개의 Dot과 하나의 Dash는 모스부호에서 V를 표현하는 방식입니다.
      </p>
      <p class="text-xl text-center break-keep">
        좋은(Good) 교점(Nodes)들을 만들어 승리(Victory)를 이끌어 내겠다는 팀 굿노드의 의지를 담았습니다.
      </p>
    </div>
    <div class="flex flex-col items-center gap-y-8">
      <p class="text-xl font-semibold break-keep text-sky-600 dark:text-sky-400">
        Who We Do?
      </p>
      <p class="text-xl text-center break-keep">
        팀 굿노드는 모터사이클과 자전거 라이더들에게 전혀 새로운 경험을 선사할 첫번째 서비스, ‘휠즈 앤 밋츠(Wheels & Meets)’를 준비하고 있습니다.
      </p>
    </div>
    <div class="flex flex-col items-center gap-y-8">
      <NuxtImg
        class="cursor-pointer"
        :src="'/image/wnm-logo.webp'"
        width="280px"
        preload
      />
      <p class="text-xl text-center break-keep">
        2010년대 이후 다양한 분야에서 ‘모바일 최적화’와 ‘디지털 전환’이 일어나고 있습니다.
      </p>
      <p class="text-xl text-center break-keep">
        그런데, 모터사이클과 자전거 분야에서도 그런 변화들이 일어났나요?
      </p>
      <p class="text-xl text-center break-keep">
        우리는 ‘두 바퀴’ 분야에 있어서 여전히 PC웹 시대에 발목 잡힌 이용자 경험이 뿌리 깊다고 진단합니다.
      </p>
      <p class="text-xl text-center break-keep">
        ‘시장이 작아서’, ‘다품종 소량 판매되는 마니악한 분야라서’, ‘각종 채팅 서비스와 소셜미디어, 카페 등으로 이용자들이 파편화되어 있어서’ 등의 이유로 ‘두 바퀴’를 대표하는 서비스는 나오지 않았죠.
      </p>
      <p class="text-xl text-center break-keep">
        2025년 상반기 중 여러분들에게 선보일 ‘휠즈 앤 밋츠(Wheels & Meets)’를 통해 이용자 누구나 가치 있는 정보에 쉽게 접근하고, 내 주변 라이더들에게 도움을 줄 수 있을거예요.
      </p>
      <p class="text-xl text-center break-keep">
        더 구체적인 설명을 드리고 싶지만, 조금만 기다려주세요!
      </p>
      <p class="text-xl text-center break-keep">
        세상에 없던 경험을 담은 서비스를 통해 인사드리겠습니다.
      </p>
      <p class="text-xl text-center break-keep">
        감사합니다.
      </p>
    </div>
  </div>
</template>
